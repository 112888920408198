var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c(
            "v-btn",
            {
              attrs: { dark: "", color: "indigo darken-1" },
              on: {
                click: function ($event) {
                  return _vm.$emit("click-back")
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-arrow-left")]), _vm._v(" 戻る ")],
            1
          ),
        ],
        1
      ),
      _vm.nextIsNecessary
        ? _c(
            "v-col",
            { staticClass: "d-flex mr-auto" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: !_vm.buttonDisabled,
                    disabled: _vm.buttonDisabled,
                    color: "indigo darken-1",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("click-next")
                    },
                  },
                },
                [
                  _vm._v(" 次に進む "),
                  _c("v-icon", [_vm._v("mdi-arrow-right")]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }